import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import Home from '@/views/Home';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: () => import('@/views/Faq'),
    },

    //parents
    {
        path: '/parents/identify',
        name: 'ParentsIdentify',
        component: () => import('@/views/Parents/Identify'),
    },
    {
        path: '/parents/election/:step',
        name: 'ParentsElection',
        props: true,
        beforeEnter: (to, from, next) => {
            if (!store.state.userData.name) next({ name: 'Home' });
            else next();
        },
        component: () => import('@/views/Parents/Election'),
    },
    {
        path: '/parents/confirm',
        name: 'ParentsConfirm',
        beforeEnter: (to, from, next) => {
            if (!store.state.userData.name) next({ name: 'Home' });
            else next();
        },
        component: () => import('@/views/Parents/Confirm'),
    },
    {
        path: '/parents/conclusion',
        name: 'ParentsConclusion',
        component: () => import('@/views/Parents/Conclusion'),
    },
    {
        path: '/parents/results',
        name: 'ParentsResults',
        component: () => import('@/views/Parents/Results'),
    },
    {
        path: '/parents/closed',
        name: 'ParentsClosed',
        component: () => import('@/views/Parents/Closed'),
    },

    //students
    {
        path: '/students/identify',
        name: 'StudentsIdentify',
        component: () => import('@/views/Students/Identify'),
    },
    {
        path: '/students/election',
        name: 'StudentsElection',
        beforeEnter: (to, from, next) => {
            if (!store.state.userData.name) next({ name: 'Home' });
            else next();
        },
        component: () => import('@/views/Students/Election'),
    },
    {
        path: '/students/confirm',
        name: 'StudentsConfirm',
        beforeEnter: (to, from, next) => {
            if (!store.state.userData.name) next({ name: 'Home' });
            else next();
        },
        component: () => import('@/views/Students/Confirm'),
    },
    {
        path: '/students/conclusion',
        name: 'StudentsConclusion',
        component: () => import('@/views/Students/Conclusion'),
    },
    {
        path: '/students/results',
        name: 'StudentsResults',
        component: () => import('@/views/Students/Results'),
    },
    {
        path: '/students/closed',
        name: 'StudentsClosed',
        component: () => import('@/views/Students/Closed'),
    },

    // lists
    {
        path: '/lists/choose',
        name: 'ListsChoose',
        component: () => import('@/views/Students/Lists/Choose'),
    },
    {
        path: '/lists/:election/identify',
        name: 'ListsIdentify',
        props: true,
        component: () => import('@/views/Students/Lists/Identify'),
    },
    {
        path: '/lists/:election/election',
        name: 'ListsElection',
        props: true,
        beforeEnter: (to, from, next) => {
            if (!store.state.userData.name) next({ name: 'Home' });
            else next();
        },
        component: () => import('@/views/Students/Lists/ListsElection'),
    },
    {
        path: '/lists/:election/election/:list',
        name: 'CandidatesElection',
        props: true,
        beforeEnter: (to, from, next) => {
            if (!store.state.userData.name) next({ name: 'Home' });
            else next();
        },
        component: () => import('@/views/Students/Lists/CandidatesElection'),
    },
    {
        path: '/lists/:election/confirm',
        name: 'ListsConfirm',
        props: true,
        beforeEnter: (to, from, next) => {
            if (!store.state.userData.name) next({ name: 'Home' });
            else next();
        },
        component: () => import('@/views/Students/Lists/Confirm'),
    },
    {
        path: '/lists/:election/conclusion',
        name: 'ListsConclusion',
        props: true,
        component: () => import('@/views/Students/Lists/Conclusion'),
    },
    {
        path: '/lists/:election/results',
        name: 'ListsResults',
        props: true,
        component: () => import('@/views/Students/Lists/Results'),
    },
    {
        path: '/lists/:election/closed',
        name: 'ListsClosed',
        props: true,
        component: () => import('@/views/Students/Lists/Closed'),
    },

    // employees
    {
        path: '/employees/:election/identify',
        name: 'EmployeesIdentify',
        props: true,
        component: () => import('@/views/Employees/Identify'),
    },
    {
        path: '/employees/:election/election',
        name: 'EmployeesListsElection',
        props: true,
        beforeEnter: (to, from, next) => {
            if (!store.state.userData.name) next({ name: 'Home' });
            else next();
        },
        component: () => import('@/views/Employees/ListsElection'),
    },
    {
        path: '/employees/:election/election/:list',
        name: 'EmployeesCandidatesElection',
        props: true,
        beforeEnter: (to, from, next) => {
            if (!store.state.userData.name) next({ name: 'Home' });
            else next();
        },
        component: () => import('@/views/Employees/CandidatesElection'),
    },
    {
        path: '/employees/:election/confirm',
        name: 'EmployeesConfirm',
        props: true,
        beforeEnter: (to, from, next) => {
            if (!store.state.userData.name) next({ name: 'Home' });
            else next();
        },
        component: () => import('@/views/Employees/Confirm'),
    },
    {
        path: '/employees/:election/conclusion',
        name: 'EmployeesConclusion',
        props: true,
        component: () => import('@/views/Employees/Conclusion'),
    },
    {
        path: '/employees/:election/results',
        name: 'EmployeesResults',
        props: true,
        component: () => import('@/views/Employees/Results'),
    },
    {
        path: '/employees/:election/closed',
        name: 'EmployeesClosed',
        props: true,
        component: () => import('@/views/Employees/Closed'),
    },

    // admin
    {
        path: '/admin',
        name: 'AdminDashboard',
        component: () => import('@/views/Admin/Dashboard'),
    },
    {
        path: '/admin/results/:target',
        name: 'AdminResults',
        props: true,
        component: () => import('@/views/Admin/Results'),
    },
    {
        path: '/admin/print/:target',
        name: 'AdminPrintResults',
        props: true,
        component: () => import('@/views/Admin/PrintResults'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        let position;
        position = { x: 0, y: 0 };
        return { ...position, behavior: 'smooth' };
    },
});

router.beforeEach((to, from, next) => {
    store.commit('updateGlobalUtilities', {
        key: 'previousRoute',
        value: from.name,
    });
    next();
});

export default router;
