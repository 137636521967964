import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const getDefaultState = () => ({
    userData: {
        id: '',
        idCode: '',
        name: '',
        surname: '',
        classroomId: '',
        classroom: '',
        sonsNumber: '',
        identifier: '',
        selection: -1,
    },
    sons: [],
    classrooms: {},
    lists: [],
    candidates: [],
    selectedCandidates: [],
    selectedCandidate: -1,

    globalUtilities: {
        showIdCodesForm: false,
        showHeader: true,
        previousRoute: '',
    },
});

export default new Vuex.Store({
    state: {
        ...getDefaultState(),
    },
    getters: {
        son: (state) => (localId) => {
            return state.sons.find((son) => son.localId === localId);
        },
    },
    mutations: {
        resetState: (state) => {
            // reset everything except previous route
            const previousRoute = state.globalUtilities.previousRoute;
            Object.assign(state, getDefaultState());
            Vue.set(state.globalUtilities, 'previousRoute', previousRoute);
        },
        updateUserData: (state, payload) => {
            Vue.set(state.userData, payload.key, payload.value);
        },
        updateSons: (state, payload) => {
            if (payload.mode === 'add') {
                Vue.set(state.sons, state.sons.length, {
                    localId: payload.localId,
                    classroomProcessed: false,
                    idCode: '',
                });
            } else if (payload.mode === 'remove')
                state.sons.splice(payload.index, payload.number);
        },
        updateSon: (state, payload) => {
            const selectedSon = state.sons.find((son) => {
                return payload.localId === son.localId;
            });
            Vue.set(selectedSon, payload.key, payload.value);
        },

        updateClassrooms: (state, payload) => {
            Vue.set(state.classrooms, payload.classroom, {
                selection: payload.selection,
                list: [...payload.list],
            });
        },

        updateClassroom: (state, payload) => {
            Vue.set(
                state.classrooms[payload.classroom],
                payload.key,
                payload.value
            );
        },

        updateLists: (state, payload) => {
            Vue.set(state.lists, state.lists.length, payload.value);
        },

        deleteCandidates: (state) => {
            state.candidates.splice(0, state.candidates.length);
        },

        updateCandidates: (state, payload) => {
            Vue.set(state.candidates, state.candidates.length, payload);
        },

        deleteSelectedCandidates: (state) => {
            state.selectedCandidates.splice(0, state.selectedCandidates.length);
        },

        updateSelectedCandidates: (state, payload) => {
            if (payload.mode === 'add')
                Vue.set(
                    state.selectedCandidates,
                    state.selectedCandidates.length,
                    payload.id
                );
            else if (payload.mode === 'remove') {
                const index = state.selectedCandidates.findIndex(
                    (candidateId) => {
                        return candidateId === payload.id;
                    }
                );
                state.selectedCandidates.splice(index, 1);
            }
        },

        updateSelectedCandidate: (state, payload) => {
            state.selectedCandidate = payload;
        },

        updateGlobalUtilities: (state, payload) => {
            Vue.set(state.globalUtilities, payload.key, payload.value);
        },
    },
    actions: {},
    modules: {},
});
