<template>
    <card-layout
        :on-click-method="onClickMethod"
        class="col-4-gaps tl:col-6-gaps tp:col-12-gaps"
    >
        <div class="card">
            <div class="card__icon">
                <slot></slot>
            </div>
            <p class="card__label">{{ label }}</p>
        </div>
    </card-layout>
</template>

<script>
import CardLayout from '@/components/CardLayout';
export default {
    name: 'NavCard',
    props: {
        label: { type: String },
        link: { type: String, default: 'Home' },
        params: { type: Object, default: null },
    },
    methods: {
        onClickMethod() {
            if (this.$route.name !== this.link)
                if (this.params)
                    this.$router.push({
                        name: this.link,
                        params: { ...this.params },
                    });
                else this.$router.push({ name: this.link });
        },
    },
    components: { CardLayout },
};
</script>

<style scoped lang="scss">
.card {
    text-align: center;
    display: flex;
    padding: 4rem 0;
    @include respond('tab-port') {
        padding: 5.5rem 0;
    }
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__icon {
        fill: ghostwhite;
        height: 7.5rem;
        width: 7.5rem;
    }
    &__label {
        margin-top: 1.1rem;
        font-family: $montserrat;
        font-size: 3.6rem;
    }
}
</style>
