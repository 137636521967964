<template>
    <div class="square">
        <div
            class="square__internal"
            :class="{ 'square__internal--active': active }"
        ></div>
    </div>
</template>

<script>
export default {
    props: ['active'],
    name: 'Square',
};
</script>

<style scoped lang="scss">
.square {
    position: absolute;
    z-index: -1;
    &__internal {
        position: absolute;
        width: 100%;
        padding-top: 100%;
        background-color: #fdfcfc;
        border: 5px solid $secondary--light;
        transition: background-color 0.3s ease-out;
        &--active {
            background-color: $secondary--light;
        }
    }
}
</style>
