<template>
    <button
        ref="button"
        class="card-layout"
        @focus="activate"
        @blur="deactivate"
        @mouseenter="activate"
        @mouseleave="deactivate"
        @click="onClickMethod"
    >
        <square class="card-layout__square" :active="active" />
        <div class="card-layout__container">
            <div
                class="card-layout__content"
                :class="{ 'card-layout__content--active': active }"
            >
                <slot></slot>
            </div>
        </div>
    </button>
</template>

<script>
import Square from '@/components/Square';
export default {
    name: 'CardLayout',
    props: {
        onClickMethod: {
            type: Function,
        },
    },
    data: () => ({
        active: false,
    }),
    methods: {
        activate() {
            this.active = true;
            this.$refs.button.focus();
        },
        deactivate() {
            this.active = false;
            this.$refs.button.blur();
        },
    },
    components: { Square },
};
</script>

<style scoped lang="scss">
.card-layout {
    position: relative;
    cursor: pointer;
    &__container {
        padding-left: calc(1 / 10 * 100%);
        padding-top: calc(1 / 10 * 100%);
        height: 100%;
    }
    &__square {
        width: calc(1 / 3 * 100%);
        height: calc(1 / 3 * 100%);
    }
    &__content {
        color: white;
        height: 100%;
        background-color: $primary;
        transition: box-shadow 0.3s ease-out, transform 0.3s ease-out;
        &--active {
            transform: translate(5px, 5px);
            box-shadow: $dim -10px -10px 25px -15px;
        }
    }
}
</style>
