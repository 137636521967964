<template>
    <div>
        <nav class="row">
            <nav-card label="Genitori" :link="parent.link">
                <svg-parent />
            </nav-card>
            <nav-card
                label="Studenti"
                :link="student.link"
                :params="{ election: student.election }"
            >
                <svg-student />
            </nav-card>
            <nav-card
                label="Impiegati"
                :link="employee.link"
                :params="{ election: employee.election }"
            >
                <svg-employee />
            </nav-card>
        </nav>
        <p class="mt-large text-center">
            Dubbi o perplessità? Consulta le nostre
            <router-link class="link" :to="{ name: 'FAQ' }">FAQ</router-link>
        </p>
    </div>
</template>

<script>
import SvgParent from '@/components/svg/Parent';
import SvgStudent from '@/components/svg/Student';
import NavCard from '@/components/NavCard';
import axios from 'axios';
import SvgEmployee from '@/components/svg/Employee';
export default {
    name: 'Home',
    data: () => ({
        accessibleElections: {
            classroom: {
                parent: '',
                student: '',
            },
            institute: {
                student: '',
                ata: '',
            },
            consult: {
                student: '',
            },
        },
    }),
    computed: {
        student() {
            if (!this.accessibleElections.classroom.student)
                return { link: 'Home' };
            else if (this.accessibleElections.classroom.student === 'closed')
                if (
                    this.accessibleElections.consult.student === 'storing' &&
                    this.accessibleElections.institute.student === 'storing'
                )
                    return { link: 'ListsChoose' };
                else if (
                    this.accessibleElections.institute.student === 'storing'
                )
                    return {
                        link: 'ListsIdentify',
                        election: 'institute',
                    };
                else if (this.accessibleElections.consult.student === 'storing')
                    return {
                        link: 'ListsIdentify',
                        election: 'consult',
                    };
                else return { link: 'StudentsClosed' };
            else if (this.accessibleElections.classroom.student === 'storing')
                return { link: 'StudentsIdentify' };
            else return { link: 'StudentsResults' };
        },
        parent() {
            if (!this.accessibleElections.classroom.parent)
                return { link: 'Home' };
            if (this.accessibleElections.classroom.parent === 'closed')
                return { link: 'ParentsClosed' };
            else if (this.accessibleElections.classroom.parent === 'storing')
                return { link: 'ParentsIdentify' };
            else return { link: 'ParentsResults' };
        },
        employee() {
            if (!this.accessibleElections.institute.ata)
                return { link: 'Home' };
            else if (this.accessibleElections.institute.ata === 'closed')
                return { link: 'EmployeesClosed', election: 'institute' };
            else if (this.accessibleElections.institute.ata === 'storing')
                return { link: 'EmployeesIdentify', election: 'institute' };
            else return { link: 'EmployeesResults' };
        },
    },
    components: {
        SvgEmployee,
        NavCard,
        SvgStudent,
        SvgParent,
    },
    mounted() {
        this.$store.commit('resetState');
        for (const election in this.accessibleElections)
            for (const typeOfUser in this.accessibleElections[election])
                axios
                    .post('/api/phase', {
                        typeOfUser: typeOfUser,
                        election: election,
                    })
                    .then(({ data }) => {
                        this.accessibleElections[election][typeOfUser] = data;
                    });
    },
};
</script>

<style scoped lang="scss">
.link {
    color: $primary;
    text-decoration: underline;
}
</style>
