<template>
    <svg viewBox="0 0 512.001 512.001">
        <g>
            <g>
                <path
                    d="M241.515,198.01c-0.863,0.24-1.73,0.453-2.597,0.638l-28.358,5.898l9.569,48.681h32.241L241.515,198.01z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M501.455,233.915c-11.056,0-60.756,0-68.693,0c2.065-2.63,3.447-5.799,3.912-9.236
			c2.166-16.001,12.206-90.155,13.609-100.518c1.417-10.458-5.914-20.083-16.371-21.5c-1.966-0.266-3.904-0.221-5.763,0.093
			c1.584-2.815,2.877-5.852,3.806-9.093c6.416-22.393-6.536-45.746-28.929-52.161c-22.392-6.416-45.746,6.536-52.161,28.929
			c-6.416,22.392,6.537,45.746,28.929,52.161c11.451,3.281,23.147,1.487,32.707-4.034c-0.026,0.161-0.068,0.315-0.089,0.478
			c-0.883,6.527-7.686,56.764-8.781,64.853l-38.178-34.109l25.927,14.374c0.51-12.82-7.74-24.808-20.622-28.499
			c-19.651-5.63-40.76-2.8-58.233,7.808c-17.474,10.608-29.724,28.03-33.797,48.061c-13.173,64.797-12.166,59.71-12.48,61.705
			h46.208c15.374,0,27.775,12.496,27.775,27.775c0,15.339-12.435,27.775-27.775,27.775c-15.857,0-28.711,0-42.123,0
			c4.977,10.637,5.398,22.304,1.982,32.697h66.818l19.054,49.406l-8.276,20.912c-4.659,11.775,1.109,25.099,12.885,29.759
			c11.765,4.658,25.097-1.102,29.759-12.884l52.052-131.539c6-15.166-5.312-31.423-21.389-31.367
			c-12.46,0.037-47.988,0.14-60.15,0.176l11.514-40.19l-27.345-40.993l55.025,49.162H391.41c-5.857,0-10.545,4.795-10.545,10.545
			c0,5.824,4.722,10.545,10.545,10.545c16.789,0,99.105,0,110.045,0c5.824,0,10.545-4.722,10.545-10.545
			S507.279,233.915,501.455,233.915z M377.024,325.655c0-5.984-3.324-11.189-8.225-13.876l20.713-0.06l-19.878,50.232l-7.908-20.504
			C370.219,341.17,377.024,334.215,377.024,325.655z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M240.638,164.864c2.124-13.688,16.694-107.59,18.619-119.993c1.705-10.988-5.82-21.275-16.807-22.981
			c-10.978-1.703-21.276,5.819-22.981,16.807c-1.99,12.822-15.323,98.751-17.054,109.905L133.62,184.27l17.438-15.013l-39.469-0.282
			c-18.747-0.134-34.006,15.068-33.955,33.805c0.039,14.341,0.282,103.131,0.313,114.342c0.042,15.597,10.803,29.233,26.178,32.76
			c-23.664,0-18.588,0-42.375,0L33.019,203.718c-1.775-9.031-10.539-14.912-19.565-13.137c-9.031,1.775-14.913,10.535-13.137,19.565
			L31.691,369.76c1.501,7.634,8.076,13.173,15.803,13.426l-32.036,83.067c-2.208,5.725,0.643,12.155,6.368,14.363
			c5.723,2.209,12.155-0.642,14.363-6.368l35.11-91.037h70.142c4.147,10.754,15.637,40.545,20.074,52.053l-8.719,22.033
			c-4.909,12.406,1.168,26.444,13.574,31.353c12.411,4.909,26.445-1.172,31.353-13.574l54.84-138.585
			c6.245-15.784-5.337-33.047-22.534-33.047l-55.539,0.162c0.219-13.901,1.283-81.518,1.447-91.858l-13.086,2.722l67.16-34.819
			C235.707,176.697,239.655,171.204,240.638,164.864z M172.695,351.928l21.822-0.063l-20.942,52.923l-8.331-21.603
			c8.948-0.292,16.116-7.619,16.116-16.637C181.359,360.243,177.858,354.76,172.695,351.928z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M312.456,269.891h-115.94c-6.136,0-11.11,4.974-11.11,11.11c0,6.089,4.935,11.11,11.11,11.11c23.732,0,92.698,0,115.94,0
			c6.136,0,11.11-4.974,11.11-11.11C323.566,274.864,318.592,269.891,312.456,269.891z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M160.012,65.418c-24.259-3.705-46.93,12.957-50.635,37.217c-3.705,24.259,12.956,46.929,37.217,50.635
			c24.279,3.708,46.94-13.023,50.635-37.217C200.934,91.794,184.271,69.123,160.012,65.418z"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'SvgStudent',
};
</script>
