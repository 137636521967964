<template>
    <svg x="0px" y="0px" viewBox="0 0 123.045 123.046">
        <g>
            <g>
                <circle cx="80.517" cy="12.692" r="12.692" />
                <path
                    d="M88.708,28.118c-0.435-0.266-0.896-0.434-1.369-0.553c-1.153-0.646-2.396-1.133-3.657-1.442l-3.162,3.183l-3.065-3.219
			c-0.012,0.002-0.021,0.003-0.031,0.006c-1.616,0.388-3.205,1.071-4.618,2.004c-0.269,0.105-0.53,0.223-0.783,0.378
			c-9.628,5.906-15.277,18.136-22.429,33.621c-0.837,1.809-0.479,3.861,0.742,5.281c-3.885,3.389-13.509-2.244-14.314-2.441
			l-2.021,2.033l-1.959-2.058c-0.007,0.002-0.015,0.004-0.021,0.006c-1.377,0.329-5.197,2.86-6.551,3.665
			c-0.128,0.05-0.256,0.098-0.379,0.167c-0.286,0.164-7.022,4.136-7.022,15.771c0,1.594,1.291,2.885,2.884,2.885
			c1.593,0,2.884-1.291,2.884-2.885c0-3.845,0.899-6.437,1.863-8.121v4.094L22.52,98.648h3.593l0.003,20.676
			c0,2.056,1.666,3.723,3.721,3.722c2.056,0,3.721-1.666,3.721-3.722l-0.002-20.676h0.531l-0.001,20.676
			c0,2.056,1.666,3.722,3.721,3.722c2.057,0,3.723-1.666,3.723-3.722l0.001-20.676h4.119l-3.347-18.209v-5.344
			c1.166,0.428,2.405,0.698,3.728,0.698c2.761,0,5.885-1.185,9.485-4.623c0.759-0.728,1.07-1.741,0.935-2.71
			c0.886-0.49,1.634-1.244,2.089-2.234c3.193-6.913,6.056-13.103,8.99-18.125v17.071c0,1.326,0.258,2.563,0.707,3.7
			c-0.033,0.256-0.058,0.516-0.058,0.779l0.004,47.188c0,3.218,2.606,5.824,5.823,5.823c3.217,0,5.824-2.607,5.822-5.823
			l-0.005-40.498c0.231,0.011,0.462,0.029,0.694,0.029c0.045,0,0.09-0.005,0.134-0.005v40.474c0,3.216,2.606,5.823,5.824,5.823
			c0.001,0,0.001,0,0.001,0c3.215,0,5.822-2.607,5.822-5.823l0.002-46.897c0.764-1.416,1.207-3.016,1.207-4.771V47.281
			c1.457,3.854,2.047,9.026,1.31,16.391c-0.271,2.708,1.705,5.122,4.413,5.395c0.167,0.016,0.33,0.022,0.496,0.022
			c2.5,0,4.643-1.896,4.898-4.437C106.842,42.484,98.286,33.992,88.708,28.118z"
                />
                <circle cx="33.998" cy="56.352" r="8.111" />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'SvgParent',
};
</script>
