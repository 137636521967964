<template>
    <svg viewBox="0 0 36 36">
        <g id="aad88ad3-6d51-4184-9840-f392d18dd002" data-name="Layer 3">
            <circle cx="16.86" cy="9.73" r="6.46" />
            <rect x="21" y="28" width="7" height="1.4" />
            <path
                d="M15,30v3a1,1,0,0,0,1,1H33a1,1,0,0,0,1-1V23a1,1,0,0,0-1-1H26V20.53a1,1,0,0,0-2,0V22H22V18.42A32.12,32.12,0,0,0,16.86,18a26,26,0,0,0-11,2.39,3.28,3.28,0,0,0-1.88,3V30Zm17,2H17V24h7v.42a1,1,0,0,0,2,0V24h6Z"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'SvgEmployee',
};
</script>

<style scoped></style>
